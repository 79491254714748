export class UIStrings {

    public async getLocaleStrings(locale: string) {
        var finalText;

        var defaultText = await this.LoadLocale("en");
        var lang = locale.substring(0, 2).toLocaleLowerCase()
        // Get the resource strings that match the language.
        switch (lang) {
            case 'en':
                finalText = defaultText;
                break;
            default:
                var localesText = {};
                try {
                    localesText = await this.LoadLocale(lang);
                    finalText = {
                        ...defaultText,
                        ...localesText
                    }
                }
                catch
                {
                    console.log("Could not load locales for " + lang);
                    finalText = defaultText;
                }

                break;
        }
        finalText["~"] = locale;
        return finalText;
    };

    public async LoadLocale(file: string) {
        var response = await fetch(process.env.BASE_URL + "locales/" + file + ".json" + "?v=" + "638673757063880258");
        var texts = await response.json();
        return texts
    }

}

export default UIStrings;
